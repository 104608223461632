<template>
  <div>
    <v-expand-transition>
    <ItemFilters v-show="showFilters" @clearFilterEvent="onClearFilterEvent"></ItemFilters>
    </v-expand-transition>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :loading="!items"
      :loading-text="$t('loading')"
      sort-by="name,code,suff"
      class="elevation-1"
      :footer-props="{ showFirstLastPage: true,firstIcon: 'mdi-page-first',lastIcon: 'mdi-page-last',prevIcon: 'mdi-chevron-left',nextIcon: 'mdi-chevron-right'}"
    >
      <template v-slot:top >
        <v-toolbar flat>
          <v-toolbar-title v-t="'items'"></v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field v-model="search" append-icon="mdi-magnify" :label="$t('search')" single-line hide-details></v-text-field>
          <v-spacer></v-spacer>
          <v-btn color="info" dark class="mb-2" @click="showFilters = !showFilters">
            <v-icon>mdi-filter</v-icon>
          </v-btn>
          <v-btn color="primary" dark class="mb-2" to="/items/new" v-t="'add'"></v-btn>
          <v-dialog v-model="dialogDelete" max-width="400">
            <v-card class="mx-auto" color="white">
              <v-card-title>
                <span class="title font-weight-light">{{ $t('deleteParam', {param:$t('item')}) }}</span>
              </v-card-title>
              <v-card-text class="headline font-weight-bold">{{ $t('deleteText', {param:$t('item')}) }}</v-card-text>
              <v-card-actions>
                <v-list-item class="grow">
                  <v-row align="center" justify="end">
                    <v-icon class="mr-1" color="success" @click="deleteItemConfirm">mdi-check</v-icon>
                    <span class="mr-1">·</span>
                    <v-icon class="mr-1" color="error" @click="closeDelete">mdi-close</v-icon>
                  </v-row>
                </v-list-item>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
    
      <template v-slot:item.itemImages="{ item }">
        <img class="mini" :src="getPic(item)" alt="" />
      </template>
    
      <template v-slot:item.active="{ item }">
        <BooleanIcon :booleanValue="item.active" />
      </template>
      <template v-slot:item.obsolete="{ item }">
        <BooleanIcon :booleanValue="item.obsolete" />
      </template>
      <template v-slot:item.itemFamilyId="{ item }">
        {{ getFamilyName(item.itemFamilyId) }}
      </template>
      <template v-slot:item.itemGroupId="{ item }">
        {{ getGroupName(item.itemGroupId) }}
      </template>
      <template v-slot:item.itemCollectionId="{ item }">
        {{ getCollectionName(item.itemCollectionId) }}
      </template>
      <template v-slot:item.barcode="{ item }">
        <span @click="download(item)" v-if="!!item.barcode">
          <barcode :ref="item.barcode"
            :type="'ean13'"
            :value="!!item.barcode? item.barcode.substr(0, 12) : '000000000000'"
            :checksum="true"
            :width="150"
            :height="50"
            :text="barcodeFont"
          ></barcode>
        </span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn dense icon raised elevation="2" color="secondary" :to="'items/'+item.id" class="mx-2"><v-icon small> mdi-pencil </v-icon></v-btn>
        <v-btn dense icon raised elevation="2" color="secondary" @click="dupItem(item)" class="mx-2"><v-icon small> mdi-source-branch-plus </v-icon></v-btn>
        <!-- <v-btn dense icon raised elevation="2" color="error" @click="deleteItem(item)"><v-icon small> mdi-delete </v-icon></v-btn> -->
      </template>
      <template v-slot:no-data>
        <NoDataImg/>
      </template>
       
    </v-data-table>

  </div>
</template>

<script>
import BooleanIcon from '@/components/app/BooleanIcon';
import ItemFilters from '@/components/filters/ItemFilters';
import NoDataImg from '../../components/NoDataImg.vue';
import { Barcode } from '@progress/kendo-barcodes-vue-wrapper';

import { call, sync } from "vuex-pathify";


export default {
  components: {
    BooleanIcon, 
    ItemFilters, 
    NoDataImg,
    Barcode
  },
  data: () => ({
    barcodeFont: {
      font: "15px Montserrat"
    },
    validItem: false,
    dialog: false,
    dialogDelete: false,
    showFilters: false,
    search: "",
    editedIndex: -1,
    editedItem: {
      barcode: "",
      barcodePrefix: "",
      barcodeSuffix: "",
      codBase: "",
      code: "",
      color: "",
      description: "",
      homoCategoryId: null,
      itemCollectionId: null,
      size: null,
      weight: null,
    },
    defaultItem: {
      barcode: "",
      barcodePrefix: "",
      barcodeSuffix: "",
      codBase: "",
      code: "",
      color: "",
      description: "",
      homoCategoryId: null,
      itemCollectionId: null,
      size: null,
      weight: null,
    },
    tempModel: {
      familyId: null,
      groupId: null,
    }
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t('newParam', {param:this.$t('item')}) : this.$t('editParam', {param:this.$t('item')});
    },
    codeRules(){
      return [
        (v) => !!v || this.$t('required', {param:this.$t('code')}),
        (v) => (v || "").indexOf(" ") < 0 || this.$t('whitespaces'),
        (v) => v.length <= 12 || this.$t('lessThan', {param:this.$t('code'),qty:v.length}),
      ];
    },
    descriptionRules(){
      return [
        (v) => v.length <= 128 || this.$t('lessThan', {param:this.$t('description'),qty:v.length}),
      ];
    },
    barcodeRules(){
      return [
        (v) => !!v || this.$t('required', {param:this.$t('barcode')}),
        (v) => (v || "").indexOf(" ") < 0 || this.$t('whitespaces'),
        (v) => v.length === 11 || v.length === 13 || this.$t('barcodeLength'),
      ];
    },
    headers(){
      return [
        {text: this.$t('image'), align: "center", value: "itemImages"},
        {text: this.$t('active'), align: "center", value: "active"},
        {text: this.$t('obsolete'), align: "center", value: "obsolete"},
        {text: this.$t('code'), align: "start", sortable: false, value: "code",},
        {text: this.$t('description'), value: "description"},
        {text: this.$t('family'), value: "itemFamilyId"},
        {text: this.$t('group'), value: "itemGroupId"},
        {text: this.$t('collection'), value: "itemCollectionId"},
        {text: this.$t('barcode'), value: "barcode"},
        {text: this.$t('actions'), value: "actions", sortable: false, align: "center"}
      ];
    },
    items: sync("items/items"),
    families: sync("families/families"),
    groups: sync("groups/groups"),
    collections: sync("collections/collections"),
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.initItemCollection();
      this.initFamilyCollection();
      this.initGroupCollection();
      this.initCollectionCollection();
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.apiItemIdDelete(this.editedItem.id);
      this.closeDelete();
    },

    dupItem(item) {
      console.log(item);
      this.showConfirm({
        message: this.$t('pleaseConfirm'),
        action: this.apiItemIdDuplicatePost,
        params: item
      })
    },  

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    getPic: function(item) {
      return ((item.itemImages ?? {})[0] ?? {}).endpoint ?? '@/assets/pittogramma-positivo-MyFamily.jpg';
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    download(item) {
      let kendobarcode = this.$refs[item.barcode].kendoWidget();
      let content = kendobarcode.svg();
      content = content.replace('d="M0 0 L 150 0 150 50 0 50Z"', 'd="M10 0 L 140 0 140 50 10 50Z"');
      content = content.replace(/(\d\d\d\d\d\d\d\d\d\d\d\d \d)/g, (str) => str.replace(' ', ''));
      
      var svgBlob = new Blob([content], {type:"image/svg+xml;charset=utf-8"});
      var svgUrl = URL.createObjectURL(svgBlob);
      var downloadLink = document.createElement("a");
      downloadLink.href = svgUrl;
      downloadLink.download = `${ item.code }.svg`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      // kendobarcode.exportSVG().done(function(data) {
      //   kendo.saveAs({
      //       dataURI: data,
      //       fileName: `${ item.code }.svg`
      //   });
      // });
    },

    save() {
      if (this.editedIndex > -1) {
        this.apiItemIdPut(this.editedItem);
      } else {
        this.apiItemPost(this.editedItem);
      }
      this.close();
    },

    getFamilyName(id) {
      return (this.families.find(el => el.id === id) || {}).description || '';
    },

    getGroupName(id) {
      return (this.groups.find(el => el.id === id) || {}).description || '';
    },

    getCollectionName(id) {
      return (this.collections.find(el => el.id === id) || {}).description || '';
    },

    onClearFilterEvent() {
      this.showFilters = false;
      this.apiItemGet();
    },
    ...call("app/*"),
    ...call("items/*"),
    ...call("families/*"),
    ...call("groups/*"),
    ...call("collections/*"),
    ...call("utils/*"),
  },
};
</script>

<style scoped>
.mini {
  height: 50px;
}
</style>